import type {FabricObject, Path} from '@postermywall/fabricjs-2';
import type {LoadSVGDataResponse} from '@Utils/fabric.util';
import {loadSVGDataFromURL} from '@Utils/fabric.util';

/**
 * Hash map of loaded icons, where icon name is the key and fabric.Object is the value.
 */
export const loadedIcons: Record<string, Path | FabricObject> = {};

/**
 * Sends the request to server for icon's svg file, fires the call back once svg file is loaded
 * @param {String} icon name of icon
 */
export const cacheIcon = (icon: string): Promise<LoadSVGDataResponse> => {
  return loadSVGDataFromURL(`${window.PMW.util.asset_url(`postermaker/menu-icons/svgs/${icon}.svg`)}`);
};

/**
 * Returns fabric.Path object representing the icon if available in the list of loaded icons, returns false otherwise
 */
export const getIconSvg = async (icon: string): Promise<Path | false> => {
  if (icon in loadedIcons) {
    return loadedIcons[icon].clone() as Promise<Path>;
  }
  return false;
};

import type {CropperModalProps} from '@Modals/cropper-modal/cropper-modal.types';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container/modal-container.types';

export const openCropperModal = (props: CropperModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CROPPER_MODAL,
      panelProps: props,
    })
  );
};

export const SOLID_COLOR_LIST = [
  'FFFFFF',
  'E0E4CC',
  'E8DDCB',
  'D5DED9',
  'D9CEB2',
  'C8C8A9',
  'CDB380',
  'ECD078',
  '99B2B7',
  'A7DBD8',
  '69D2E7',
  '4ECDC4',
  'CFF09E',
  'A8DBA8',
  '79BD9A',
  '3B8686',
  '0B486A',
  '036564',
  '033649',
  '031634',
  'F38630',
  'FA6900',
  'D95B43',
  'C02942',
  '542437',
  'FF6B6B',
  'F9CDAD',
  'FC9D9A',
  'FE4365',
  'C44D58',
  'C7F464',
  '000000',
  '7A6A53',
];

export const COLOR_BACKGROUND_MODAL_ID = 'color-background-modal';
export const BACKGROUND_GRADIENT_TYPE_LIST_ID = 'Background-gradient-type-dropdown';

export const enum BACKGROUND_TYPE {
  SOLID = 0,
  GRADIENT = 1,
}

export const enum GRADIENT_TYPE {
  LINEAR = 'linear',
  RADIAL = 'radial',
}

export interface BackgroundColorType {
  backgroundType: BACKGROUND_TYPE;
  primaryColor: string;
  secondaryColor: string;
  gradientType: GRADIENT_TYPE;
}

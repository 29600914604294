import type {MenuItemData} from '@Panels/user-menu-panel/user-menu-panel.types';
import {MENU_ITEM_TYPE} from '@Panels/user-menu-panel/user-menu-panel.types';
import {getColumnCountForLayout, getColumnsForLayout} from '@PosterWhiteboard/items/layouts/layout.library';
import type {CellObject, CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import {Cell} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {LayoutData} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import type {MenuItem, MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {cloneDeep} from 'lodash';
import type {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';

export const prepareDataForMenuItem = (items: Array<MenuItemData>, layout: string): Partial<MenuItemObject> => {
  const menuLayout: Record<string, Array<CellObject>> = cloneDeep(getColumnsForLayout(layout as LayoutTypes));
  const columnIds = Object.keys(menuLayout) as Array<CellType>;

  columnIds.forEach((columnId) => {
    for (let i = 0; i < items.length; i++) {
      const val = items[i][columnId as keyof MenuItemData] ?? false;
      menuLayout[columnId].push(new Cell(columnId, val).toObject());
    }
  });

  const menuItemIds = items.map((item) => {
    return item.id;
  });

  return {
    layoutDataMap: menuLayout,
    rows: items.length,
    columns: getColumnCountForLayout(layout as LayoutTypes),
    itemIds: menuItemIds,
    layoutStyle: layout as LayoutData,
  };
};

export const getSelectedMenuItems = (menuItem: MenuItem): Array<MenuItemData> => {
  const selectedItems = [];
  const menuData = menuItem.layoutDataMap;
  for (let i = 0; i < menuItem.itemIds.length; i++) {
    selectedItems.push({
      type: MENU_ITEM_TYPE.USER_MENU_ITEM,
      id: `${menuItem.itemIds[i]}`,
      name: menuData.name?.[i].getValue(),
      description: menuData.description?.[i].getValue(),
      price: menuData.price?.[i].getValue(),
      iconsList: menuData.iconsList?.[i].getValue(),
      variationsList: menuData.variationsList ? menuData.variationsList[i].getValue() : menuData.sizesList?.[i].getValue(),
      addOnsList: menuData.addOnsList?.[i].getValue(),
      categories: [],
      locations: [],
    } as MenuItemData);
  }
  return selectedItems;
};

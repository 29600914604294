import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {VariationData, AddOnData, Cell} from '@PosterWhiteboard/items/layouts/cells/cell';
import {rgbToHexString} from '@Utils/color.util';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {BOLD_STROKE_WIDTH_FACTOR, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {Group, IText, Textbox} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

const MIN_WIDTH_FOR_DESCRIPTION = 250;

export class MenuLayout1 extends Layout {
  public layoutType: LayoutTypes.MENU_LAYOUT_1 = LayoutTypes.MENU_LAYOUT_1;
  declare item: MenuItem;

  public constructor(item: MenuItem) {
    super(item);
  }

  public setStylesForHighlightedItems(): void {
    throw new Error('Method not implemented.');
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.horizontallyCenterItems(this.item.fabricObject);
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   * @override
   */
  async setItems(): Promise<void> {
    const textObjectCaching = !this.item.page.poster.isHighRes;
    const columnMap = this.item.getColumnMap();
    const rows = this.item.getNoOfRows();
    const horizontalGroups: Array<Group> = [];

    for (let i = 0; i < rows; i++) {
      const priceGroup = new Group([], {
        // @ts-expect-error new custom property for fabric
        itemType: CellType.PRICE,
        objectCaching: false,
      });
      const finalGroup = new Group([], {objectCaching: false});
      const iconsGroup = new Group([], {
        // @ts-expect-error new custom property for fabric
        itemType: CellType.ICONS,
        objectCaching: false,
      });
      const addOnsGroup = new Group([], {
        // @ts-expect-error new custom property for fabric
        itemType: CellType.ADDON,
        objectCaching: false,
      });

      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        let t = cells[i].getValue() as string;

        switch (cellType) {
          case CellType.NAME:
            finalGroup.insertAt(
              finalGroup.getObjects().length,
              new IText(t, {
                editable: false,
                itemType: cellType,
                objectCaching: textObjectCaching,
              })
            );
            break;
          case CellType.DESCRIPTION:
            /*
             * At highres, we don't let the Textbox determine text wrapping. Instead, we specify wrapping ourselves that matches
             * what the user saw in their browser when the poster was saved
             */
            if (t.length > 0) {
              if (this.item.page.poster.isHighRes) {
                t = this.getWrapping(this.item, i, t);
              }
              finalGroup.insertAt(
                finalGroup.getObjects().length,
                new Textbox(t, {
                  editable: false,
                  itemType: cellType,
                  objectCaching: textObjectCaching,
                })
              );
            }
            break;
          case CellType.PRICE:
            priceGroup.insertAt(
              priceGroup.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;
          case CellType.ICONS:
            for (let z = 0; z < t.length; z++) {
              // eslint-disable-next-line no-await-in-loop
              const icon = await getIconSvg(t[z]);

              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });

                iconsGroup.insertAt(iconsGroup.getObjects().length, icon);
              }
            }
            break;
          case CellType.VARIATION: {
            const cellVal = cells[i].getValue() as Array<VariationData>;
            if (cellVal.length > 0) {
              priceGroup.remove(priceGroup.getObjects()[priceGroup.getObjects().length - 1]);
              for (const variationData of cellVal) {
                priceGroup.insertAt(
                  priceGroup.getObjects().length,
                  new IText(`${variationData.name} ${variationData.price}`, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
              }
            }
            break;
          }
          case CellType.ADDON: {
            const cellVal = cells[i].getValue() as Array<AddOnData>;
            for (const addonData of cellVal) {
              addOnsGroup.add(
                new IText(`${addonData.name} ${addonData.price}`, {
                  editable: false,
                  objectCaching: textObjectCaching,
                })
              );
            }
            break;
          }
          default:
            break;
        }
      }

      // add all items in order
      finalGroup.add(priceGroup);
      if (addOnsGroup.getObjects().length > 0) {
        finalGroup.add(addOnsGroup);
      }
      if (iconsGroup.getObjects().length > 0) {
        finalGroup.add(iconsGroup);
      }

      horizontalGroups.push(finalGroup);
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * Sets the view styles specific to this menu layout
   * @private
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Array<Group>;
    const strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeColor = rgbToHexString(this.item.textStyles.fill.fillColor[0], 1);
    let strokeWidthForFF1 = strokeWidth;
    let strokeWidthForFF2 = 0;
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 += !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth * 1.3;
      strokeColor = rgbToHexString(this.item.textStyles.strokeColor, 1);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects();
      let itemName = null;
      let itemDescription = null;
      let priceGroup = null;
      let iconsGroup = null;
      let addOnsGroup = null;

      for (let b = 0; b < groupItems.length; b++) {
        // @ts-expect-error new custom property for fabric
        switch (groupItems[b].itemType) {
          case CellType.NAME:
            itemName = groupItems[b];
            itemName.set({
              fontSize: this.item.textStyles.fontSize * 1.3,
              fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
              strokeWidth: strokeWidthForFF1,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
              underline: this.item.textStyles.underLine,
              linethrough: this.item.textStyles.lineThrough,
            });
            break;
          case CellType.DESCRIPTION:
            itemDescription = groupItems[b];
            itemDescription.set({
              textAlign: 'center',
              fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
              width: MIN_WIDTH_FOR_DESCRIPTION + this.item.xSpacing,
              fontFamily: `'${fontFamily2WithVariation}'`,
              underline: this.item.underLine2,
              linethrough: this.item.lineThrough2,
            });
            if (strokeWidthForFF2) {
              itemDescription.set({
                strokeWidth: strokeWidthForFF2,
                paintFirst,
                strokeLineJoin: 'round',
                stroke: strokeColor,
              });
            }
            break;
          case CellType.PRICE:
            priceGroup = groupItems[b] as Group;
            priceGroup.getObjects().forEach((obj) => {
              obj.set({
                fontSize: this.item.textStyles.fontSize * 1.1,
                fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
                strokeWidth: strokeWidthForFF1,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
                underline: this.item.textStyles.underLine,
                linethrough: this.item.textStyles.lineThrough,
              });
            });
            this.verticallyStackItems(priceGroup, 15, 0);
            this.verticallyCenterItems(priceGroup);
            break;
          case CellType.ICONS:
            iconsGroup = groupItems[b] as Group;
            this.verticallyStackItems(iconsGroup, 15, 0);
            this.verticallyCenterItems(iconsGroup);
            iconsGroup.set({
              fill: rgbToHexString(this.item.iconsColor, this.item.alpha),
            });
            break;
          case CellType.ADDON:
            addOnsGroup = groupItems[b] as Group;
            addOnsGroup.set({
              fontFamily: `'${fontFamily2WithVariation}'`,
            });
            addOnsGroup.getObjects().forEach((obj) => {
              obj.set({
                fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
                underline: this.item.underLine2,
                linethrough: this.item.lineThrough2,
              });
              if (strokeWidthForFF2) {
                obj.set({
                  strokeWidth: strokeWidthForFF2,
                  strokeLineJoin: 'round',
                  paintFirst,
                  stroke: strokeColor,
                });
              }
            });
            this.verticallyStackItems(addOnsGroup, 15, 0);
            this.verticallyCenterItems(addOnsGroup);
            break;
          default:
        }
      }
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Array<Group>;

    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'horizontal');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });
      this.horizontallyStackItems(groups[i], 3, 0);
      this.horizontallyCenterItems(groups[i]);
    }
  }

  /**
   * Returns the wrapping info for this layout
   */
  getWrappingInfo(): Array<Array<string>> {
    const menuItems = this.item.fabricObject.getObjects() as Array<Group>;
    const wrappingData = [];

    for (const menuItem of menuItems) {
      const items = menuItem.getObjects() as Array<Group>;
      if (items[1] instanceof Textbox) {
        wrappingData.push(items[1].textLines);
      } else {
        wrappingData.push('');
      }
    }

    return wrappingData as Array<Array<string>>;
  }
}
